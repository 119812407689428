import React, { useEffect, useRef, useState } from "react";
import $ from "jquery";
import "jquery-confirm/css/jquery-confirm.css";
import "jquery-confirm";
import { useHistory, useParams, withRouter } from "react-router-dom";
import ManualJuego from "../components/manualJuego";
import TraerCuponesPendientesDesktop from "../components/traerCuponesPendientesDesktop";
import TraerCuponesPendientesLotoides from "../components/traerCuponesPendientesLotoides";

const Juego = (props) => {
  //----------------HOOKS DE ESTADO -----------------------
  const [urlIframeJuego, seturlIframeJuego] = useState(undefined);
  const { nombrejuego } = useParams();
  const [mandoMsj, setMandoMsj] = useState(false);
  const iframeRef = useRef();
  const [iframeContent, setIframeContent] = useState();

  //----------------- HISTORY -------------------------------
  const history = useHistory();

  //------------------ HOOKS DE EFECTO ----------------------
  useEffect(() => {
    if (props.estadoPlataforma.estado === "PLATAFORMA") {
      history.push("/home");
    }
  });

  useEffect(() => {
    switch (nombrejuego) {
      case "Quinielatradicional":
        seturlIframeJuego(
          process.env.REACT_APP_URL_BASE_FRONTEND +
            process.env.REACT_APP_URL_JUEGO_TOMBOLA
        );
        break;
      case "Quiniela Instantánea":
        seturlIframeJuego(
          process.env.REACT_APP_URL_BASE_FRONTEND +
            process.env.REACT_APP_URL_JUEGO_TOMBO_EXPRESS
        );
        break;
      case "quinielainstantanea":
        seturlIframeJuego(
          process.env.REACT_APP_URL_BASE_FRONTEND +
            process.env.REACT_APP_URL_JUEGO_TOMBO_EXPRESS
        );
        break;
      case "patagoniaminibingo":
        seturlIframeJuego(
          process.env.REACT_APP_URL_BASE_FRONTEND +
            process.env.REACT_APP_URL_JUEGO_PATA_MINIBINGO
        );
        break;
      case "patagoniatelebingo":
        seturlIframeJuego(
          process.env.REACT_APP_URL_BASE_FRONTEND +
            process.env.REACT_APP_URL_JUEGO_PATA_TELEBINGO
        );
        break;
      case "Tombola":
        seturlIframeJuego(
          process.env.REACT_APP_URL_BASE_FRONTEND +
            process.env.REACT_APP_URL_JUEGO_TOMBOLA
        );
        break;
      case "QuiniExpress":
        seturlIframeJuego(
          process.env.REACT_APP_URL_BASE_FRONTEND +
            process.env.REACT_APP_URL_JUEGO_TOMBO_EXPRESS
        );
        break;
      case "TomboExpress":
        seturlIframeJuego(
          process.env.REACT_APP_URL_BASE_FRONTEND +
            process.env.REACT_APP_URL_JUEGO_TOMBO_EXPRESS
        );
        break;
      case "pozodelaquiniela":
        seturlIframeJuego(
          process.env.REACT_APP_URL_BASE_FRONTEND +
            process.env.REACT_APP_URL_JUEGO_POZO
        );
        break;
      case "loteriaunificada":
        seturlIframeJuego(
          process.env.REACT_APP_URL_BASE_FRONTEND +
            process.env.REACT_APP_URL_JUEGO_LOTERIA_UNIFICADA
        );
        break;
      case "raspadita_money":
        seturlIframeJuego(process.env.REACT_APP_URL_JUEGO_RASPADITA);
        window.setmanualJuego(
          <ManualJuego
            className="navbar-brand"
            manualesJuego="https://issuu.com/arteymania/docs/manual_apostador__2018_-_corregido_/28?fr=sZGIzNjM2ODMyNzU "
            style={{ zIndex: "999" }}
          />
        );
        window.setnotificacionesJuego(
          <TraerCuponesPendientesDesktop
            className="mr-4"
            pendientes={0 /*cuponesPend.cuponesSinSortear*/}
            traerCupones={() => console.log()}
            refreshCup={() => console.log()}
            juego={nombrejuego}
            estadoPlataforma={props.estadoPlataforma}
          />
        );
        break;
      case "quini6":
        console.log(props);

        seturlIframeJuego(
          process.env.REACT_APP_URL_BASE_FRONTEND +
            process.env.REACT_APP_URL_JUEGO_QUINI
        );

        window.setmanualJuego(
          <ManualJuego
            className="navbar-brand"
            manualesJuego="https://www.loteriadesalta.com/como-jugar/#tab-3157"
            style={{ zIndex: "999" }}
          />
        );

        window.setnotificacionesJuego(
          <TraerCuponesPendientesLotoides
            juego={nombrejuego}
            estadoPlataforma={props.estadoPlataforma}
          />
        );

        break;
      case "lotoplus":
        seturlIframeJuego(
          process.env.REACT_APP_URL_BASE_FRONTEND +
            process.env.REACT_APP_URL_JUEGO_LOTOPLUS
        );
        window.setmanualJuego(
          <ManualJuego
            className="navbar-brand"
            manualesJuego="https://www.loteriadesalta.com/como-jugar/#tab-3156"
            style={{ zIndex: "999" }}
          />
        );

        window.setnotificacionesJuego(
          <TraerCuponesPendientesLotoides
            juego={nombrejuego}
            estadoPlataforma={props.estadoPlataforma}
          />
        );

        break;
      default:
        break;
    }
  }, []);

  useEffect(() => {
    // Intervalo para chequeo de Datalayer de Kambi.

    if (document.getElementsByClassName("zonaJuego_raspadita_money")[0]) {
      const raspaditaDataLayerInterval = setInterval(() => {
        const raspaditaDataLayer = document.getElementsByClassName(
          "zonaJuego_raspadita_money"
        )[0].contentWindow.dataLayer;

        // Se busca evento de GTM relacionado a navegacion.
        const raspaditaPagefilter = (data) =>
          "kambi" in data && data.event == "kambi page view";
        let kambiPageElement = raspaditaDataLayer.findLast(raspaditaPagefilter);
        let kambiPageElementIndex =
          raspaditaDataLayer.findLastIndex(raspaditaPagefilter);

        console.log(kambiPageElement, kambiPageElementIndex);

        // // Se busca evento de GTM relacionado a navegacion en sandwich menu.
        // const kambiSandwichClickFilter = (data) =>
        //   "kambi" in data && data.event == "kambi sandwich filter click";
        // let kambiSandwichClickElement = kambiDataLayer.findLast(
        //   kambiSandwichClickFilter
        // );
        // let kambiSandwichClickElementIndex = kambiDataLayer.findLastIndex(
        //   kambiSandwichClickFilter
        // );

        // // Se busca evento de GTM relacionado a cambios de eventos en QR.
        // const kambiBetSlipFilter = (data) =>
        //   "kambi" in data &&
        //   (data.event == "kambi add to betslip" ||
        //     data.event == "kambi remove from betslip");
        // let kambiBetSlipElement = kambiDataLayer.findLast(kambiBetSlipFilter);
        // let kambiBetSlipElementIndex =
        //   kambiDataLayer.findLastIndex(kambiBetSlipFilter);

        // // Si existe elemento de nevegacion y no es el mismo path de URL actual...
        // if (!!kambiPageElement && kambiPageElement.kambi.page.path != kambiPath) {
        //   setkambiPath(kambiPageElement.kambi.page.path);
        // }

        // // Si existe elemento de nevegacion en sandwitch menu...
        // if (!!kambiSandwichClickElement) {
        //   let DLEvent = {
        //     event: "sandwich_nav",
        //     indexInDL: kambiSandwichClickElementIndex,
        //     data: kambiSandwichClickElement.kambi.interaction,
        //   };

        //   // Y no existe ya en el dataLayer...
        //   if (
        //     !window.dataLayer.find(
        //       (data) =>
        //         data.event == "sandwich_nav" &&
        //         data.indexInDL == DLEvent.indexInDL
        //     )
        //   ) {
        //     window.dataLayer.push(DLEvent);
        //   }
        // }

        // // Si existe elemento de cambio en BetSlip...
        // if (!!kambiBetSlipElement) {
        //   let DLEvent = {
        //     event:
        //       kambiBetSlipElement.event == "kambi add to betslip"
        //         ? "betslip_add_event"
        //         : "betslip_remove_event",
        //     indexInDL: kambiBetSlipElementIndex,
        //     data: kambiBetSlipElement.kambi.ecommerce,
        //   };

        //   // Y no existe ya en el dataLayer...
        //   if (
        //     !window.dataLayer.find(
        //       (data) =>
        //         (data.event == "betslip_add_event" ||
        //           data.event == "betslip_remove_event") &&
        //         data.indexInDL == DLEvent.indexInDL
        //     )
        //   ) {
        //     window.dataLayer.push(DLEvent);
        //   }
        // }
      }, 5000);

      return () => clearInterval(raspaditaDataLayerInterval);
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    props.arrancarJuego(urlIframeJuego);

    if (
      urlIframeJuego !== undefined &&
      nombrejuego === "raspadita_money" &&
      !mandoMsj
    ) {
      setTimeout(() => {
        let juego = iframeRef.current.contentWindow;
        juego.postMessage(
          props.estadoPlataforma.userToken,
          process.env.REACT_APP_URL_JUEGO_RASPADITA
        );

        window.addEventListener("message", (e) => {
          //console.log(e);
          if (e.data === "ActualizoSaldoMoney") {
            setTimeout(() => {
              window.refreshSaldo();
            }, 1000);
          }
        });
        setMandoMsj(true);
      }, 1000);
    }
  }, [urlIframeJuego, nombrejuego]);

  /**
   * Se ejecuta la funcion @terminoCargaJuego proveniente del componente padre
   */
  const cargoElIFrame = () => {
    props.terminoCargaJuego();
  };

  return props.estadoPlataforma.estado === "PLATAFORMA" ? (
    <></>
  ) : (
    <>
      {urlIframeJuego !== undefined && (
        <iframe
          ref={iframeRef}
          onLoad={cargoElIFrame}
          src={urlIframeJuego}
          style={{ visibility: "visible" }}
          title="juego"
          id="zonaJuego"
          className={`zonaJuego_${nombrejuego}`}
          sandbox="allow-same-origin allow-scripts allow-downloads"
          frameBorder="0"
        />
      )}

      {/*  <iframe
        ref={iframeRef}
        src={"http://localhost:3001"}
        style={{ visibility: "visible" }}
        title="prueba"
        id="zonaJuego"
      />*/}
    </>
  );
};

export default withRouter(Juego);
