import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { estilosProv } from "../configProvincias";
import styled from "styled-components";
import NumberFormat from "react-number-format";
import { Animated } from "react-animated-css";

const TraerCuponesPendientesDesktop = (props) => {
  const [cupones, setCupones] = useState(undefined);
  const codigoOrg = window.parent.getCodigoOrganizacion();
  const [logoJuego6, setlogoJuego6] = useState(undefined);
  const [logoJuego7, setlogoJuego7] = useState(undefined);
  const [logoJuego8, setlogoJuego8] = useState(undefined);
  const [cuponActual, setcuponActual] = useState(undefined);
  const [logoNoDiponible, setLogoNoDiponible] = useState(undefined);
  const borderBottomColor = estilosProv[codigoOrg].borderBottomColor;
  const borderTopColor = estilosProv[codigoOrg].borderTopColor;

  const cargarImgDinamica = () => {
    process.env.REACT_APP_ENABLE_RASPADITA !== undefined
      ? import(`../img/LOGO_RASPADITA_${codigoOrg}.png`).then((image) => {
          setlogoJuego6(image.default);
        })
      : import(`../img/LOGO_RASPADITA.png`).then((image) => {
          setlogoJuego6(image.default);
        });
    process.env.REACT_APP_ENABLE_QUINI !== undefined
      ? import(`../img/LOGO_JUEGO_4_${codigoOrg}.png`).then((image) => {
          setlogoJuego7(image.default);
        })
      : import(`../img/LOGO_JUEGO_4_53.png`).then((image) => {
          setlogoJuego7(image.default);
        });
    process.env.REACT_APP_ENABLE_LOTOPLUS !== undefined
      ? import(`../img/LOGO_JUEGO_3_${codigoOrg}.png`).then((image) => {
          setlogoJuego8(image.default);
        })
      : import(`../img/LOGO_JUEGO_3_53.png`).then((image) => {
          setlogoJuego8(image.default);
        });

    import(`../img/defaultImageError.png`).then((img) => {
      setLogoNoDiponible(img.default);
    });
  };

  const traerCupones = (url) => {
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${props.estadoPlataforma.userToken}`,
        },
      })
      .then((res) => res.data)
      .then((data) => setCupones(data.cupones))
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    let parametros = {
      userToken: props.estadoPlataforma.userToken,
    };
    // console.log(props);
    let url = "";
    if (props.juego === "raspadita_money") {
      url =
        process.env.REACT_APP_URL_BASE_BACKEND +
        process.env.REACT_APP_WS_JUEGOS_EXTERNOS_HISTORICO +
        parametros.userToken;
    }
    if (props.juego === "quini6") {
      url =
        process.env.REACT_APP_URL_BASE_BACKEND +
        process.env.REACT_APP_WS_QUINI6_HISTORICO;
      url = url.replace(":codigoJuego", 3);
    }
    if (props.juego === "lotoplus") {
      url =
        process.env.REACT_APP_URL_BASE_BACKEND +
        process.env.REACT_APP_WS_LOTOPLUS_HISTORICO;
      url = url.replace(":codigoJuego", 4);
    }
    cargarImgDinamica();
    console.log(url);

    traerCupones(url);
  }, []);

  const ModalHeader = () => (
    <div className="modal-header">
      <div className="container-fluid">
        <div className="row justify-content-center">
          {props.juego.replace("_", " ")}
        </div>
      </div>{" "}
      <button
        type="button"
        className="close"
        data-dismiss="modal"
        aria-label="Close"
      >
        {" "}
      </button>
      <span aria-hidden="true">×</span>
    </div>
  );
  const ModalBody = () => (
    <div className="modal-body">
      <div className="contaier">
        <div
          style={{
            display: "grid",
          }}
          className="row justify-content-center"
        >
          <img style={{ marginLeft: "auto" }} src={logoJuego6} />
          <div className="col-12 text-center">
            Jugado el{" "}
            {cuponActual?.fechaCompra.slice(0, 10).replaceAll("-", "/")}
          </div>
        </div>
        <div className="col-12 text-center">
          {cuponActual?.premio && (
            <Animated animationIn="bounceIn" animationInDelay={200}>
              <div
                style={{
                  width: "60%",
                  position: "relative",
                  zIndex: "1",
                }}
                className="bg-success mb-2 rounded p-1 text-light mx-auto font-weight-bold"
              >
                PREMIO{" "}
                <NumberFormat
                  value={cuponActual?.montoPremio}
                  displayType={"text"}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  prefix={"$ "}
                  decimalScale={2}
                  fixedDecimalScale={false}
                  isNumericString={true}
                />
              </div>
            </Animated>
          )}

          {!cuponActual?.premio && (
            <div>
              <Animated animationIn="flipInX" animationInDelay={200}>
                <div className="bg-info mb-2 rounded p-2 text-light w-90 mx-auto text-center">
                  {cuponActual?.estado === "CONFIRMADO"
                    ? "Cupón sin premio"
                    : "CUPON PENDIENTE A RASPAR"}
                </div>
              </Animated>
            </div>
          )}
        </div>
      </div>

      <div className="container">
        <div className="row justify-content-center">
          <div className="col-8 mt-4 mb-4">
            {cuponActual && (
              <img
                className={"bg-prov-" + process.env.REACT_APP_COD_ORGANIZACION}
                style={{ width: "-webkit-fill-available" }}
                src={
                  !cuponActual.ulrImgCarton.includes("https://")
                    ? logoNoDiponible
                    : cuponActual.ulrImgCarton
                }
              />
            )}
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-12 text-center">
            <SpanTotalCupon>Total cupón </SpanTotalCupon>
            <NumberFormat
              className="m-heavy op-text"
              style={{
                fontSize: "22px",
                verticalAlign: "middle",
                fontFamily: "Roboto",
                color: "#8a8a8a",
              }}
              value={cuponActual?.monto} //modificar el valor por
              displayType={"text"}
              thousandSeparator={"."}
              decimalSeparator={","}
              prefix={"$ "}
              decimalScale={2}
              fixedDecimalScale={false}
              isNumericString={true}
            />
          </div>
          <div className="col-12 text-center">
            Nro. Serie : {cuponActual?.nroSerieCarton}
          </div>
          <div className="col-12 text-center">
            Nro. Cartón : {cuponActual?.nroCarton}
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <div
        data-toggle="modal"
        data-target="#juegoRaspaditaModal"
        style={
          ["13", "43", "11", "41", "23", "53", "20", "50", "18", "48"].includes(
            window.getCodigoOrganizacion()
          )
            ? { cursor: "pointer", color: "white", width: "fit-content" }
            : { cursor: "pointer", color: "black", width: "fit-content" }
        }
        className="text-center text-small"
        id="pend"
        onClick={() => {
          let url = "";
          if (props.juego === "raspadita_money") {
            url =
              process.env.REACT_APP_URL_BASE_BACKEND +
              process.env.REACT_APP_WS_JUEGOS_EXTERNOS_HISTORICO +
              props.estadoPlataforma.userToken;
          }
          if (props.juego === "quini6") {
            url =
              process.env.REACT_APP_URL_BASE_BACKEND +
              process.env.REACT_APP_WS_QUINI6_HISTORICO;
            url = url.replace(":codigoJuego", 3);
          }
          if (props.juego === "lotoplus") {
            url =
              process.env.REACT_APP_URL_BASE_BACKEND +
              process.env.REACT_APP_WS_LOTOPLUS_HISTORICO;
            url = url.replace(":codigoJuego", 4);
          }
          cargarImgDinamica();
          traerCupones(url);
          //props.traerCupones(false);
          //props.setshowModalCupones(true);
        }}
      >
        <div
          className="iconify"
          data-icon="uil:ticket"
          data-bs-inline="false"
          data-rotate="90deg"
          data-width="25px"
        />
      </div>
      {/*---------------------------MODAL JUEGO --------------------------------------------*/}

      <div
        style={{ position: "fixed", top: "6%" }}
        className="modal fade"
        id="juegoRaspaditaModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog"
          role="document"
          style={{ boxShadow: "0px 0px grey" }}
        >
          <div className="modal-content pb-2">
            <div className="modal-header">
              <h5
                className="modal-title text-center"
                id="juegoRaspaditaModalLabel"
              >
                CUPONES
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true"> X </span>
              </button>
            </div>
            <div
              className="modal-body pt-0"
              style={{ maxHeight: "60vh", overflow: "scroll" }}
            >
              <div
                className="modal-body text-center mb-2 pb-5"
                style={{ overflowY: "scroll" }}
              >
                <table
                  id="TableConsCupones"
                  style={{ fontSize: "14px" }}
                  className="table text-center table-striped"
                >
                  <tbody>
                    {cupones?.map((cupon) => (
                      <tr key={"cupon_" + cupon.cuponId}>
                        <td
                          style={{ fontSize: "15px", position: "relative" }}
                          className="v-align-middle text-left pr-0"
                        >
                          {cupon.premio && (
                            <Animated
                              style={{
                                position: "absolute",
                                left: "0",
                                top: "-5%",
                              }}
                              animationIn="bounceIn"
                              animationInDelay={800}
                            >
                              <span
                                className="iconify text-warning"
                                data-icon="bx:bxs-trophy"
                                data-inline="false"
                                data-width="17px"
                              ></span>
                            </Animated>
                          )}
                          <div
                            className="op-text"
                            style={{
                              left: "1.5vw",
                              position: "relative",
                              marginTop: "0.3vh",
                            }}
                          >
                            {cupon?.fechaCompra
                              .slice(0, 10)
                              .replaceAll("-", "/") +
                              " - " +
                              cupon.fechaCompra.substring(11, 19)}
                          </div>
                        </td>

                        <td
                          style={{ fontSize: "20px" }}
                          className="v-align-middle text-success m-bold"
                        >
                          {"$ " + cupon.monto}
                        </td>

                        <td className="v-align-middle p-0" onClick={() => {}}>
                          <button
                            type="button"
                            data-toggle="modal"
                            data-target="#verCuponModal"
                            data-dismiss="modal"
                            aria-label="Close"
                            className="btn btn-primary m-bold v-align-middle pr-4 pl-4"
                            style={{ marginTop: "0.6vh" }}
                            onClick={() => setcuponActual(cupon)}
                          >
                            VER
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div
              className="modal-footer bg-light"
              style={{ display: "flex", justifyContent: "space-evenly" }}
            >
              <button
                className="btn btn-success p-2 pl-3 pr-3"
                data-dismiss="modal"
                aria-label="Close"
              >
                CERRAR
              </button>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div
          className="modal fade"
          id="verCuponModal"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="basicModal"
          aria-hidden="true"
        >
          <ModalDialog className="modal-dialog modal-dialog-centered modal-dialog-scrollable mt-2">
            <DivBorderStyle
              borderTopColor={borderTopColor}
              borderBottomColor={borderBottomColor}
              className="modal-content"
            >
              <ModalHeader />
              <ModalBody />
            </DivBorderStyle>
          </ModalDialog>
        </div>
      </div>
      {/*-------------MODAL CUPON-----------------------------*/}
      <div>
        <div
          className="modal fade"
          id="verCuponModal"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="basicModal"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable mt-2">
            <DivBorderStyle
              borderTopColor={borderTopColor}
              borderBottomColor={borderBottomColor}
              className="modal-content"
            ></DivBorderStyle>
          </div>
        </div>
      </div>
    </>
  );
};
export default TraerCuponesPendientesDesktop;
const DivBorderStyle = styled.div`
  border-top: ${(props) => `10px solid ${props.borderTopColor}`};
  border-bottom: ${(props) => `10px solid ${props.borderBottomColor}`};
  box-shadow: 15px 25px 20px -15px;
  border-radius: 1em;
  margin-top: 1em;
`;

const SpanTotalCupon = styled.span`
  font-family: "Roboto" !important;
`;

const ModalDialog = styled.div`
  box-shadow: 0 0 0 transparent !important;
`;
